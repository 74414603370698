import "./src/styles/global.css"
import "./src/styles/project.css"
//for testing
// gatsby-browser.js
import "swiper/swiper-bundle.min.css"

import React from "react"

export const wrapPageElement = ({ element, props }) => {
  return <>{element}</>
}
